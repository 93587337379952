import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

// $(document).foundation();

// $("#addedCartMsg, #deliveryNP, #deliveryAddress").hide();
$(document).on('click', '.add-to-cart', function() {

    $("#addedCartMsg").fadeIn(300);
    setTimeout(function() {
        $("#addedCartMsg").fadeOut(300);
    }, 1200);
});

setTimeout(function() {
    $(document).ready(function() {
        $(document).foundation();
        $('#pre-logo').fadeOut(); // will first fade out the loading animation
        $('#preloader').fadeOut('slow');

        $(document).on('click', 'input[type="radio"]', function() {

            // $('input[type="radio"]').click(function() {
            if ($(this).attr('id') == 'inputDeliveryNP') {
                $('#deliveryNP').show(500).prop('required', true);
            } else {
                $('#deliveryNP').hide(500).removeAttr('required');
            }
            if ($(this).attr('id') == 'inputDeliveryAddress') {
                $('#deliveryAddress').show(500).prop('required', true);
            } else {
                $('#deliveryAddress').hide(500).removeAttr('required');
            }
        });

        $("#checkout > .button").hover(function() {
                $("#cartView").fadeIn(300);

            },
            function() {
                $("#cartView").fadeOut(300);

            });

        $(".sort-btn").click(function() {
            $("i", this).toggleClass('fa-sort-amount-down-alt fa-sort-amount-down');

        });




    });

}, 1200);


$('#thankyou-close').click(function() {
    $('#thankyou_message').css("display", "none");
    $('#gform').css("display", "block");
    // resetNonNg();

});

$('#frameOrder .close-button').click(function() {

    resetNonNg();
});

function resetNonNg() {
    $('#gform').find('input:text, input:password, input:file, select, textarea').val('');

}

$(document).keyup(function(e) {
    if (e.which == 27) {
        resetNonNg();
    } // esc
});